import React from 'react';
import FormInput from '../../FormInput';
import { Controller, useForm } from 'react-hook-form';
import FormSelect from '../../FormSelect';
import Button from '../../Button';
import { submitBtnTxt } from '../../../helpers/functions';
import { ApplyIcon, ClearIcon } from '../../../assets/icons/useIcons';
import Tooltip from '../../Tooltip';

const Filter = ({ filterDefaultValues = [], isLoading, setFilters }) => {
  const { watch, control, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      filter: filterDefaultValues?.map((item) => {
        return {
          key: item?.key,
          value: '',
        };
      }),
    },
  });

  const clearOtherFields = (mapIndex) => {
    const filters = watch('filter');
    for (let index = 0; index < filters.length; index++) {
      if (index !== mapIndex) {
        setValue(`filter.${index}.value`, '');
      }
    }
  };

  const onSubmit = (_) => {
    if (_?.filter?.filter((item) => item.value).length > 0) {
      setFilters(_?.filter?.filter((item) => item.value));
    }
  };

  return (
    <form
      className="d--flex gap--sm flex--column-xs w--full filterSearch d--none-xs"
      onSubmit={handleSubmit(onSubmit)}
    >
      {filterDefaultValues.map((item, index) => {
        const {
          label = '',
          placeholder = '',
          id = '',
          access = true,
          type = 'text',
          options = [],
        } = item || {};

        if (!access) return null;

        if (['text', 'number', 'email', 'date'].includes(type)) {
          return (
            <Controller
              key={id}
              name={`filter.${index}.value`}
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type={type}
                  label={label}
                  placeholder={placeholder}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearOtherFields(index);
                  }}
                  autocomplete="off"
                  required={false}
                />
              )}
            />
          );
        }

        if (['select'].includes(type)) {
          return (
            <Controller
              key={id}
              name={`filter.${index}.value`}
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  type={type}
                  label={label}
                  placeholder={placeholder}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearOtherFields(index);
                  }}
                  options={options}
                  required={false}
                />
              )}
            />
          );
        }

        return <></>;
      })}
      <div className="d--flex align-items--center">
        <div className="d--flex flex--column w-min--32 w-max--32">
          {filterDefaultValues.some((v) => v.label) && (
            <label className="label--control font--sm font--500 m-b--sm   text--grey emptyLabelbtn">
              &nbsp;
            </label>
          )}
          <Tooltip text="Apply">
            <Button
              variant="transparent"
              borderColor="success"
              color="success"
              size=""
              btnClasses="btn h-min--32 h-max--32  p--0 "
            >
              {/* {submitBtnTxt(isLoading, 'Apply')} */}

              <ApplyIcon height={20} width={20} />
            </Button>
          </Tooltip>
        </div>
        <div className="d--flex flex--column w-min--32 w-max--32 ">
          {filterDefaultValues.some((v) => v.label) && (
            <label className="label--control font--sm font--500 m-b--sm   text--grey emptyLabelbtn">
              &nbsp;
            </label>
          )}
          <Tooltip text="Clear">
            <Button
              variant="transparent"
              borderColor="danger"
              color="danger"
              btnClasses="btn  w-max--40 h-min--40 h-max--40 p--0"
              onClick={() => {
                reset();
                setFilters([]);
              }}
            >
              <ClearIcon height={21} width={21} />
              {/* Clear */}
            </Button>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};

export default Filter;
