import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../../Modal';
import SignModule from '../../../../SignModule';
import moment from 'moment';

const initialDocData = {
  Insurance_Agent: '',
  Agency_Name: '',
  Agent_Phone: '',
  Consumer: '',
  Consumer_Address: '',
  Consumer_Phone: '',
  DATE: '',
  SIGNATURE: '',
};

const HRA_EN = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);
    const { getHraData, getConsumerAddress } = documentData || {};

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Insurance_Agent'] =
            getHraData?.agentDetail?.fullName ?? '';
          prevState['Agency_Name'] = '';
          prevState['Agent_Phone'] = getHraData?.agentDetail?.phone ?? '';
          prevState['Consumer'] = getHraData?.consumerDetail?.fullName ?? '';
          prevState['Consumer_Address'] =
            getConsumerAddress?.consumerInfo?.address ?? '';
          prevState['Consumer_Phone'] = getHraData?.consumerDetail?.phone ?? '';
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '100%',
            margin: '10px auto',
            padding: '10px 20px',
            maxWidth: '794px',
          }}
          className="pdfForm"
        >
          <table
            cellSpacing="0"
            style={{
              width: '100%',
              padding: '15px 0',
              borderCollapse: 'collapse',
              borderSpacing: '0',
            }}
          >
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Consumer Consent and Release Agreement for Insurance Coverage
                  Health Risk Assessment
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  This Consumer Consent and Release Agreement (“Agreement”) is
                  made and entered into on the date of signature by and between
                </p>
              </td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '130px' }}
                      >
                        Insurance Agent:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Insurance_Agent}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '110px' }}
                      >
                        Agency Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '140px' }}
                      >
                        Agent contact No.:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agent_Phone}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{
                          lineHeight: '1',
                          margin: '0',
                          width: '140px',
                          fontWeight: 'bold',
                          paddingLeft: '60px',
                        }}
                      >
                        And
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '80px' }}
                      >
                        Consumer
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '160px' }}
                      >
                        Consumer’s Address:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer_Address}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '130px' }}
                      >
                        Consumer’s No.:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer_Phone}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  1. Purpose
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  The purpose of this Agreement is to obtain Consumer’s consent
                  to participate in a Health Risk Assessment (“HRA”) and to
                  permit the Insurance Agent to collect and share the Consumer’s
                  health- related information as required for the evaluation and
                  management of Consumer’s health insurance plan with its
                  carrier (“Insurance Carrier”)
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  2. Scope of Consent
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '10px' }}>
                  By signing this Agreement, the Consumer consents to:
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Participate in the Health Risk Assessment, which may involve
                  collecting health information to assess current health
                  conditions, lifestyle, and risk factors, as outlined by
                  applicable Center for Medicare and Medicaid Services (CMS) and
                  Centers for Disease Control and Prevention (CDC) guidelines
                  and Insurance Carrier criteria.
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Allow the Insurance Agent to collect, use, and share the
                  information obtained during the HRA with the Consumer’s
                  Insurance Carrier to facilitate accurate health plan
                  recommendations, risk analysis, and appropriate follow-up
                  actions.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  3. Use and Disclosure of Protected Health Information (PHI)
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  All health information collected is potentially considered
                  Protected Health Information (“PHI”) as defined under the
                  Health Insurance Portability and Accountability Act of 1996
                  (“HIPAA”), and as may be amended. In executing this Agreement,
                  the Consumer authorizes the Insurance Agent to handle and
                  disclose PHI in compliance with HIPAA and other applicable
                  state and federal laws that govern the privacy and security of
                  health information, in order to facilitate the HRA for the
                  Consumer and Insurance Carrier.
                </p>
                <p style={{ lineHeight: '2' }}>
                  The Insurance Agent and Insurance Carrier shall only use and
                  disclose Consumer’s PHI for purposes directly related to
                  Consumer’s health insurance plan, assessment, and coverage
                  options. No other unauthorized third party shall have access
                  to the Consumer’s PHI, regardless of whether such PHI is
                  contained within the Consumer’s HRA
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  4. Compliance with HIPAA and Related Laws
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  The Insurance Agent agrees to comply with HIPAA and any/all
                  applicable state and federal privacy laws in handling
                  Consumer’s PHI. Consumer’s information will only be used and
                  shared as outlined in this Agreement and as required for legal
                  and regulatory purposes.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  5. Consumer’s Rights
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '10px' }}>
                  The Consumer has the right to:
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Withdraw consent at any time by providing written notice to
                  the Insurance Agent.
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Access, request amendments, or obtain a copy of the PHI
                  collected during the HRA as permitted by law
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - To receive a notice of any unauthorized access, use, or
                  disclosure of PHI in compliance with applicable laws
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  6. Release of Liability
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  The Consumer fully and completely releases and disclaims the
                  Insurance Agent and the Insurance Carrier from any liability
                  arising from the use, disclosure, and handling of PHI as
                  permitted under this Agreement, for the purposes of the HRA,
                  and/or by applicable law.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  7. Acknowledgment
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  The Consumer acknowledges that participation in the Health
                  Risk Assessment is voluntary and that the Consumer has been
                  informed of their rights under HIPAA and those related to the
                  HRA. The Consumer understands that failure to provide or
                  authorize certain health information and/or accurate health
                  information may affect the accuracy of recommendations and
                  coverage options.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',

                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  8. Term
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  This consent shall remain in effect until the purpose of the
                  Health Risk Assessment and associated evaluation is fulfilled,
                  unless revoked in writing by the Consumer.
                </p>
              </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '180px' }}
                      >
                        Consumer Name (Printed):
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '60px' }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {moment(documentState.DATE).format('MMMM Do YYYY')}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Consumer Signature:
                      </p>
                    </td>
                    <td>
                      <div style={{ position: 'relative' }}>
                        {!documentState?.SIGNATURE ? (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              height: '60px',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-60px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            + Add Signature
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-45px',
                              height: '60px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            <img
                              alt="signature"
                              src={documentState?.SIGNATURE}
                              style={{ maxHeight: '50px' }}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default HRA_EN;
