import { API_ROUTES } from './routes';
import { apiClient } from './axios';

export const getUserDetails = (data) => {
  return apiClient({
    method: API_ROUTES.USER_DETAILS.METHOD,
    url: API_ROUTES.USER_DETAILS.URL,
    data,
  });
};
export const updatePassword = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_PASSWORD.METHOD,
    url: API_ROUTES.UPDATE_PASSWORD.URL,
    data,
  });
};
export const updateAgency = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_AGENCY.METHOD,
    url: API_ROUTES.UPDATE_AGENCY.URL,
    data,
  });
};
export const updateAgent = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_AGENT.METHOD,
    url: API_ROUTES.UPDATE_AGENT.URL,
    data,
  });
};
export const updateConsumer = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_CONSUMER.METHOD,
    url: API_ROUTES.UPDATE_CONSUMER.URL,
    data,
  });
};
export const updateTenant = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_TENANT.METHOD,
    url: API_ROUTES.UPDATE_TENANT.URL,
    data,
  });
};
export const getDocStatus = (data) => {
  return apiClient({
    method: API_ROUTES.GET_DOC_STATUS.METHOD,
    url: API_ROUTES.GET_DOC_STATUS.URL,
    data,
  });
};
export const updateClaimStatus = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_CLAIM_STATUS.METHOD,
    url: API_ROUTES.UPDATE_CLAIM_STATUS.URL,
    data,
  });
};
export const getNotifications = (data) => {
  return apiClient({
    method: API_ROUTES.GET_NOTIFICATIONS.METHOD,
    url: API_ROUTES.GET_NOTIFICATIONS.URL,
    data,
  });
};
export const updateNotification = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_NOTIFICATION_STATUS.METHOD,
    url: API_ROUTES.UPDATE_NOTIFICATION_STATUS.URL,
    data,
  });
};

export const verifySignDoc = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.VERIFY_DOC.METHOD,
    url: API_ROUTES.VERIFY_DOC.URL,
    data,
  });
};
export const verifySignDocNew = (data) => {
  return apiClient({
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    method: API_ROUTES.VERIFY_DOC_NEW.METHOD,
    url: API_ROUTES.VERIFY_DOC_NEW.URL,
    data,
  });
};

export const companyProfileUpdate = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.COMPANY_PROFILE_UPDATE.METHOD,
    url: API_ROUTES.COMPANY_PROFILE_UPDATE.URL,
    data,
  });
};
export const uploadClaim = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.UPLOAD_CLAIM.METHOD,
    url: API_ROUTES.UPLOAD_CLAIM.URL,
    data,
  });
};
export const uploadChatDoc = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.UPLOAD_CHAT_FILE.METHOD,
    url: API_ROUTES.UPLOAD_CHAT_FILE.URL,
    data,
  });
};
export const getAgentInviteList = (data) => {
  return apiClient({
    method: API_ROUTES.INVITE_AGENT_LIST.METHOD,
    url: API_ROUTES.INVITE_AGENT_LIST.URL,
    data,
  });
};
export const inviteAgent = (data) => {
  return apiClient({
    method: API_ROUTES.INVITE_AGENT.METHOD,
    url: API_ROUTES.INVITE_AGENT.URL,
    data,
  });
};
export const inviteUpdateStatus = (data) => {
  return apiClient({
    method: API_ROUTES.INVITE_UPDATE_STATUS.METHOD,
    url: API_ROUTES.INVITE_UPDATE_STATUS.URL,
    data,
  });
};
export const uploadToDrive = (data) => {
  return apiClient({
    method: API_ROUTES.UPLOAD_TO_DRIVE.METHOD,
    url: API_ROUTES.UPLOAD_TO_DRIVE.URL,
    data,
  });
};
export const getChatFiles = (data) => {
  return apiClient({
    method: API_ROUTES.GET_CHAT_FILE.METHOD,
    url: API_ROUTES.GET_CHAT_FILE.URL,
    data,
  });
};
export const handleWebhook = ({ webhook = '', ...rest }) => {
  return apiClient({
    method: API_ROUTES.WEBHOOK.METHOD,
    url: webhook,
    data: rest,
  });
};
export const webhookInboundTest = ({ webhook = '', ...rest }) => {
  return apiClient({
    method: API_ROUTES.WEBHOOK_INBOUND_TEST.METHOD,
    url: API_ROUTES.WEBHOOK_INBOUND_TEST.URL,
    data: rest,
  });
};
export const documentVerification = (data) => {
  return apiClient({
    baseURL: 'https://conxent.nextere.com:8090/api/',
    method: API_ROUTES.DOCUMENT_VERIFICATION.METHOD,
    url: API_ROUTES.DOCUMENT_VERIFICATION.URL,
    data,
  });
};
export const uploadMedia = (data) => {
  return apiClient({
    method: API_ROUTES.UPLOAD_MEDIA_URL.METHOD,
    url: API_ROUTES.UPLOAD_MEDIA_URL.URL,
    data,
  });
};
export const getDocumentStatus = (data) => {
  return apiClient({
    method: API_ROUTES.GET_DOCUMENT_STATUS.METHOD,
    url: API_ROUTES.GET_DOCUMENT_STATUS.URL,
    data,
  });
};
export const verifySignatureDoc = (data) => {
  return apiClient({
    method: API_ROUTES.VERIFY_SIGNATURE_DOC.METHOD,
    url: API_ROUTES.VERIFY_SIGNATURE_DOC.URL,
    data,
  });
};
export const sendOtpForSignDoc = (data) => {
  return apiClient({
    method: API_ROUTES.SEND_OTP_FOR_SIGN_DOC.METHOD,
    url: API_ROUTES.SEND_OTP_FOR_SIGN_DOC.URL,
    data,
  });
};
export const verifyOtpForSignDoc = (data) => {
  return apiClient({
    method: API_ROUTES.VERIFY_OTP_FOR_SIGN_DOC.METHOD,
    url: API_ROUTES.VERIFY_OTP_FOR_SIGN_DOC.URL,
    data,
  });
};
