import React, { useEffect, useState } from 'react';
import FormSelect from '../../../components/FormSelect';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { HRAList } from '../../Dashboard/actions';
import { defaultValues, hraSchema, relationArr } from '../constants';
import { getObjectLength, submitBtnTxt } from '../../../helpers/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HRAForm from './HRAForm';
import useIcons from '../../../assets/icons/useIcons';
import { State, City } from 'country-state-city';
import FormInput from '../../../components/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionConfirmation from '../../../components/ActionConfirmation';
import { hraDelete } from '../actions';
import { useAlert } from '../../../hooks/useAlert';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));
const HRAStep3 = () => {
  const [searchParams] = useSearchParams();
  const consumerId = searchParams.get('id');
  const { showAlert } = useAlert();
  const { state } = useLocation();

  const navigate = useNavigate();
  const { CrossRoundIcon, AddIcon, RefreshIcon, BackRoundIcon } = useIcons();
  const [show, setShow] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [cityList, setCityList] = useState([]);
  const [relationList, setRelationList] = useState(relationArr);
  const [carrierList, setCarrierList] = useState([]);
  // const [providerList, setProviderList] = useState([]);

  const { data, refetch } = useQuery({
    queryKey: ['HRAList', consumerId],
    queryFn: () => HRAList({ consumerId }),
    select: (data) => data?.data?.data,
  });

  const { mutate } = useMutation({
    mutationFn: hraDelete,
    mutationKey: 'hraDelete',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      refetch();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(hraSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'member',
  });

  const handleSubmitForm = (data) => {
    console.log('🚀 ~ handleSubmitForm ~ data:', data);
  };

  let [watchState] = watch(['consumer_info.state']);
  useEffect(() => {
    if (data && getObjectLength(data)) {
      const { consumerHraAssessmentInfo, consumerHraAssessmentList = [] } =
        data || {};
      if (consumerHraAssessmentInfo || consumerHraAssessmentList?.length > 0) {
        reset({
          consumer_info: consumerHraAssessmentInfo?.consumerInfo,
          member: consumerHraAssessmentList?.map((val) => {
            const { consumerRelationship, _id } = val || {};
            return { ...consumerRelationship, relationId: _id };
          }),
        });
      }
      setSelectedField(0);
    }
  }, [data, carrierList]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    } else {
      setCityList([]);
    }
  }, [watchState]);

  useEffect(() => {
    if (fields.length === 1) {
      setSelectedField(0);
    }
  }, [fields]);

  useEffect(() => {
    if (watch('member')) {
      const existingRelations = watch('member').map((item) =>
        item.relation?.trim(),
      );
      const filteredArr = relationArr.filter(
        (item) => !existingRelations.includes(item.value),
      );

      setRelationList(filteredArr);
    }
  }, [JSON.stringify(watch('member') || [])]);

  const fetchIssuers = async () => {
    try {
      const response = await fetch(
        `https://marketplace.api.healthcare.gov/api/v1/issuers?apikey=d687412e7b53146b2631dc01974ad0a4&year=${2024}&state=${watchState}&limit=${25}&offset=${0}`,
      );
      const result = await response.json();
      setCarrierList(result?.issuers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchIssuers();
  }, [watchState]);

  // const fetchProviders = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://marketplace.api.healthcare.gov/api/v1/providers/autocomplete?apikey=d687412e7b53146b2631dc01974ad0a4&q=hospital&zipcode=19123&type=Facility`,
  //     );
  //     const result = await response.json();
  //     setProviderList(result);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchProviders();
  // }, []);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="d--flex flex--column gap--xl "
    >
      <div className="d--flex justify-content--start w--full w-max--600 ">
        <div
          onClick={() =>
            navigate('/HRA', {
              state: { activeStep: 2 },
            })
          }
          className="d--flex align-items--center gap--xs text--black c--pointer font--sm"
        >
          <span className="d--flex">
            <BackRoundIcon width={25} height={25} />
          </span>
          <span className="font--500">Back</span>
        </div>
      </div>
      <div className="d--flex flex--column  border-full--black-100 radius--md gap--xl ">
        <div className="w--full d--flex  gap--lg p--lg h-min--100">
          <div className="w--full d--flex">
            <FormSelect
              label="Year"
              placeholder="Select Year"
              options={[
                {
                  label: '2024',
                  value: '2024',
                },
                {
                  label: '2025',
                  value: '2025',
                },
              ]}
              {...register('consumer_info.year')}
              error={errors?.consumer_info?.year?.message}
            />
          </div>
          <div className="w--full">
            {' '}
            <Controller
              name="consumer_info.state"
              control={control}
              rules={{
                onChange() {
                  setValue('consumer_info.city', '');
                },
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="State"
                  error={errors?.consumer_info?.state?.message}
                  height="40"
                  options={statesList}
                  placeholder="Select State"
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="consumer_info.city"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="City "
                  error={errors?.consumer_info?.city?.message}
                  height="40"
                  options={cityList}
                  placeholder="Select City"
                />
              )}
            />
          </div>
          <div className="w--full d--flex">
            <FormInput
              label="Address"
              placeholder="#"
              error={errors?.consumer_info?.address?.message}
              {...register(`consumer_info.address`)}
            />
          </div>

          {!show ? (
            <div className="d--flex justify-content--center gap--md w--full m-t--lg ">
              <Button
                type="button"
                variant="primary"
                color="black"
                icon={<AddIcon width={16} height={16} />}
                btnClasses="btn w-max--200 w-min--200 gap--sm"
                onClick={() => setShow(true)}
              >
                {submitBtnTxt(false, 'Add Member')}
              </Button>
            </div>
          ) : (
            <div className="w--full d--flex">
              <FormSelect
                label="Relation"
                placeholder="Select relationship"
                options={relationList}
                onChange={(e) => {
                  append({
                    relation: e?.target?.value,
                    data: {
                      personal_demographic_info: {
                        name: state?.firstName + ' ' + state?.lastName,
                        date_of_birth: '',
                        gender: '',
                        marital_status: '',
                        occupation: '',
                        primary_care_provider: '',
                        carrierId:
                          data?.consumerHraAssessmentList[0]
                            ?.consumerRelationship?.data
                            ?.personal_demographic_info?.carrierId || '',
                        relation: e?.target?.value,
                      },
                      diagnosed_with_conditions: [],
                      diagnosed_with_conditions_otherValue: '',
                      prescription_medications: '',
                      allergies: '',
                      surgery_in_past_5_years: '',
                      chronic_pain: '',
                      family_diagnosed_with_conditions: [],
                      family_diagnosed_with_conditions_other: '',
                      substance_use: {
                        tobacco: false,
                        alcohol: false,
                        recreational_drugs: false,
                      },
                      sleep_hours: '',
                      exercise_schedule: '',
                      typeofEx: '',
                      typeofExValue: '',
                      stress_level: '',
                      current_diet_rating: '',
                      last_physical_exam: '',
                      vaccinations_received_in_past: [],
                      vaccinations_received_in_past_other: '',
                      last_mammogram: '',
                      last_pap_smear: '',
                      prostate_exam_psa: '',
                      had_colonoscopy: '',
                      past_two_week: {
                        feeling_down_depressed: '',
                        little_please_in_things: '',
                      },
                      diagnosed_with_mental_health_condition: '',
                      seeing_mental_health_professional: '',
                      top_health_priorities: [],
                      interests_in_wellness_programs: [],
                      interests_in_wellness_programs_other: '',
                      health_info_usage_permission: '',
                      care_management_consent: '',
                    },
                  });
                  setShow(false);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="d--flex gap--lg profilePageTabe justify-content--between border-bottom--black-100  overflow--auto">
        <div className="d--flex gap--lg ">
          {fields.map((field, index) => {
            const isErrorExist = Boolean(errors?.member?.[index]?.data);
            return (
              <div className="d--flex align-items--center">
                <div
                  className={`p-t--sm p-b--sm p-l--md p-r--md h-min--40 c--pointer font--sm font--600 d--flex align-items--center border-bottom--transparent justify-content--center gap--sm white-space--nowrap radius--topLeftRight--sm  ${
                    selectedField === index
                      ? `border-bottom--black ${
                          isErrorExist ? 'text--danger' : 'text--black'
                        }  bg--black-50`
                      : `  ${
                          isErrorExist ? 'text--danger-600' : 'text--black-600'
                        }`
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedField(index);
                  }}
                  key={field.id}
                >
                  {watch(`member.${index}.relation`)}
                  {/* {field.relation} */}

                  {!watch(`member.${index}.relationId`) ? (
                    <div
                      className="c--pointer d--flex"
                      onClick={(e) => {
                        e.stopPropagation();

                        remove(index);
                        setSelectedField(index - 1);
                      }}
                    >
                      <CrossRoundIcon width={18} height={18} />
                    </div>
                  ) : (
                    <ActionConfirmation
                      message="Are you sure you want to remove?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        mutate({ uuId: watch(`member.${index}.relationId`) });
                      }}
                      confirmBtnText="Yes"
                      btnText="Cancel"
                    >
                      <div className="c--pointer d--flex">
                        <CrossRoundIcon width={18} height={18} />
                      </div>
                    </ActionConfirmation>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className=" bg--black-50 text--black c--pointer  w-min--40 h-min--40 w-max--40 h-max--40 radius--topLeftRight--sm d--flex align-items--center justify-content--center"
          onClick={() => {
            refetch();
          }}
        >
          <RefreshIcon />
        </div>
      </div>
      {fields.map((field, index) => {
        if (index !== selectedField) return null;
        const err = errors?.member?.[index]?.data;
        return (
          <HRAForm
            {...{
              register,
              errors: err,
              watch,
              setValue,
              index: selectedField,
              getValues,
              trigger,
              refetch,
              consumerId,
              carrierList,
              // providerList,
              rowData: data?.consumerHraAssessmentList[index],
            }}
          />
        );
      })}
    </form>
  );
};

export default HRAStep3;
