import React, { useState } from 'react';
import TableManager from '../../../components/TableManager';
import { dropEl } from '../../../helpers/constants';
import Dropdown from '../../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { hraConsumerList } from '../actions';
import { filterDefaultValues } from '../../Members/constants';
import AsideModal from '../../../components/AsideModal';
import HRAView from '../HRAView';

const HRAStep2 = () => {
  const [openAsideModal, setOpenAsideModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  console.log('🚀 ~ HRAStep2 ~ rowData:', rowData);
  const navigate = useNavigate();
  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'HRA':
        navigate(`/view-hra?id=${rowData?.consumerId}`, {
          state: rowData,
        });

        break;

      default:
        break;
    }
  };
  const columns = [
    {
      accessorKey: 'firstName',
      header: () => 'Name',
      cell: (props) => `${props.getValue()} ${props.row.original?.lastName}`,
      meta: {
        width: '30%',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        width: '25%',
      },
    },
    {
      accessorKey: 'familyMembers',
      header: () => 'Members',
      cell: (props) => {
        return (
          <div
            onClick={() => {
              if (props.getValue()?.length > 0) {
                setOpenAsideModal(true);
                setRowData(props?.row?.original);
              }
            }}
            className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
          >
            {props.getValue()?.length}
          </div>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => 'Enrollment',

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        return (
          <>
            <div className="w-min--125 w-min--auto-xs">
              <Dropdown
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (!item?.access) return;
                    return (
                      <div
                        className="text--secondary p--sm w--full text--l"
                        onClick={() =>
                          handleProfileDropClick(
                            item?.value,
                            props.row?.original,
                            item,
                          )
                        }
                      >
                        {item?.title}
                      </div>
                    );
                  },

                  data: [
                    {
                      title: 'HRA',
                      value: 'HRA',
                      id: 1,
                      access: true,
                    },
                  ],
                }}
              >
                {dropEl}
              </Dropdown>
            </div>
          </>
        );
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  return (
    <div className="w--full d--flex flex--column gap--lg memberPage">
      <TableManager
        {...{
          fetcherKey: 'hraConsumerList',
          fetcherFn: hraConsumerList,
          shouldFilter: false,
          name: 'Consumer',
          columns: columns,

          selectCBForTableData: (tblData) =>
            tblData?.data?.data?.memberResponseData,
          filterDefaultValues: filterDefaultValues,
        }}
      />
      {openAsideModal ? (
        <AsideModal
          handleClose={() => setOpenAsideModal(false)}
          title={'HRA'}
          footerComponent={null}
          headerComponent={null}
          size={'lg'}
          width={'lg'}
        >
          <HRAView
            handleClose={() => setOpenAsideModal(false)}
            uuId={rowData?.consumerId}
          />
        </AsideModal>
      ) : null}
    </div>
  );
};

export default HRAStep2;
