import React, { useState } from 'react';
import Button from '../../../components/Button';
import { submitBtnTxt } from '../../../helpers/functions';
import SendAggrementModal from './SendAggrementModal';
import Modal from '../../../components/Modal';
import useIcons from '../../../assets/icons/useIcons';
import { useQueryClient } from '@tanstack/react-query';

const HRAStep1 = ({ setActiveStep }) => {
  const [openModal, setOpenModal] = useState(false);
  const { RefreshIcon } = useIcons();
  const queryClient = useQueryClient();

  return (
    <div className="w--full d--flex flex--column gap--xl HRAPage h--full position--relative">
      <div
        className="position--absolute bg--black-50 text--black c--pointer right--10 top--10 w-min--40 h-min--40 w-max--40 h-max--40 radius--md d--flex align-items--center justify-content--center"
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: ['getUserDetails'],
            exact: false,
          });
        }}
      >
        <RefreshIcon />
      </div>
      <div className="d--flex flex--column  border-full--black-100 radius--md p--md h--full justify-content--between">
        <div>
          <div className="font--xl font--800 h-min--50  d--flex  p-l--md p-r--md p-t--md ">
            Unlock More Revenue with Health Risk Assessments (HRAs)
          </div>
          <div className="font--lg font--500 h-min--50 d--flex align-items--center  p-l--md p-r--md">
            Streamline Consumer Engagement, Increase Earnings, and Enhance
            Compliance
          </div>
          <div className="font--sm font--400 h-min--50  d--flex align-items--center  p-l--md p-r--md">
            Completing Health Risk Assessments (HRAs) isn't just about
            compliance.
            {/* {user?.agreementDocumentDetail?.agreementDocumentStatus === 'NO'
            ? 'We have send agreement to your email/sms, please check and sign to continue. If you have not received agreement yet, please try again.'
            : 'To Join and Start, please sign consent agreement.'} */}
          </div>
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                It's a valuable opportunity to grow your business while
                supporting consumers effectively. Here's how the HRA program can
                benefit you:
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                Increased Revenue: Earn additional payments for every completed
                HRA submitted to carriers.
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                Enhanced Consumer Support: Help consumers make informed
                decisions by identifying their unique health needs.
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                Effortless Compliance: Stay compliant with CMS regulations while
                improving your professional credibility.
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                Treamlined Process: Use our tools to complete HRAs quickly and
                efficiently, saving time and effort
              </li>
            </div>
            <div className="font--lg font--500 h-min--50    p-l--md p-r--md p-t--md">
              Take advantage of this program today and maximize your potential
              as an agent!
            </div>
          </div>
        </div>

        <div className="d--flex justify-content--center gap--md w--full  m-t--xl m-b--xl">
          <Button
            type="submit"
            variant="primary"
            color="black"
            btnClasses="btn w-max--200 w-min--200"
            disabled={false}
            onClick={() => setOpenModal(true)}
          >
            {submitBtnTxt(false, 'Send HRA Agreement')}
          </Button>
        </div>
      </div>

      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          handleClose={() => setOpenModal(false)}
          headerComponent={null}
          footerComponent={null}
          title={'Send Agreement'}
        >
          <SendAggrementModal
            handleClose={() => setOpenModal(false)}
            {...{ setActiveStep }}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default HRAStep1;
