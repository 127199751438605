import { useParams } from 'react-router-dom';
import useIcons from '../../../../assets/icons/useIcons';
import { useQuery } from '@tanstack/react-query';
import {
  getHraAgreementSignedDocStatus,
  getHraSignedDocStatus,
} from '../../../../pages/HRA/actions';
import { toast } from 'react-toastify';
import {
  downloadFileFromURL,
  generateUniqueId,
  getOS,
} from '../../../../helpers/functions';
import NoDocument from '../../../NoDocument';
import Spinner from '../../../Spinner';
import moment from 'moment';
import Button from '../../../Button';
import { useState } from 'react';
import AsideModal from '../../../AsideModal';
import HRA_AGREEMENT_EN from '../../HRA/Agreement/en';

const HRAAgreementPreviewDocument = () => {
  const { CheckIcon, CalendarIcon, FileIcon, UserLineIcon } = useIcons();
  const { docId = '' } = useParams();
  const [openModal, setOpenModal] = useState(false);

  const { data = null, isLoading } = useQuery({
    queryFn: () =>
      getHraAgreementSignedDocStatus({
        uuId: docId,
      }),
    queryKey: 'getHraAgreementSignedDocStatus',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
    enabled: Boolean(docId),
  });

  const documentDetails = data?.data?.data?.getHraData;
  console.log(
    '🚀 ~ HRAAgreementPreviewDocument ~ documentDetails:',
    documentDetails,
  );
  const certData = data?.data?.data?.verificationData;

  function handleDownload(url) {
    if (!url) return;
    downloadFileFromURL(url);
  }

  const documentLookup = {
    ES: (
      <HRA_AGREEMENT_EN readMode={true} preFillData={documentDetails?.data} />
    ),
    EN: (
      <HRA_AGREEMENT_EN readMode={true} preFillData={documentDetails?.data} />
    ),
  };

  const document = documentLookup[
    `${documentDetails?.getHraData?.language === 'en' ? 'EN' : 'ES'}`
  ] || <NoDocument />;

  if (!docId) return <NoDocument />;

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="d--flex h--full w--full ">
      {getOS() && ['windows', 'macos'].includes(getOS()) && (
        <div className="d--flex gap--sm w--full">
          <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
            {document}
          </div>
        </div>
      )}
      <div
        className={` d--flex flex--column gap--md   w--full h--full bg--white  ${
          getOS() && ['windows', 'macos'].includes(getOS()) ? 'w-max--400' : ''
        }  `}
      >
        <div className="d--flex flex--column gap--md   w--full h--full p--md overflow--auto  sidePdfDetails">
          <div className="d--flex w--full  border-full--success bg--success-200 radius--sm p--md gap--sm align-items--center justify-content--center">
            <CheckIcon /> This document is digitally signed.
          </div>

          {certData && Object.keys(certData).length > 0 ? (
            <>
              <div className="d--flex w--full flex--column  gap--xs">
                <label className="label--control font--sm font--500  d--inline-block text--black-600">
                  Digital Sign Details
                </label>
                <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
                  <div className="d--flex gap--xs flex--column w--full">
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <UserLineIcon width={18} height={18} />
                      </div>
                      <div>HRA Agreement Document</div>
                      {/* <div>{certData?.signatureMeta?.reason} </div> */}
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <CalendarIcon width={18} height={18} />
                      </div>
                      <div>
                        {moment(documentDetails?.docSignedDate).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <FileIcon width={18} height={18} />
                      </div>
                      <div>{certData?.signatureMeta?.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Signature
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 h-max--75  radius--sm p--sm d--flex align-items--center justify-content--center">
              <img
                alt="signature"
                src={documentDetails?.data?.SIGNATURE}
                style={{ maxHeight: '65px' }}
              />
            </div>
          </div>

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Email
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              {documentDetails?.agentDetail?.email}
            </div>
          </div>
          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Signature Time
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              {moment(documentDetails?.docSignedDate).format(
                'MMMM Do YYYY, h:mm A',
              )}
            </div>
          </div>

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              OS Details
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              <div className="d--flex gap--md flex--column w--full p--sm">
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    IP Address:
                  </div>
                  <div>{documentDetails?.ipAddress}</div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Session ID:
                  </div>
                  <div>{`${generateUniqueId()}`}</div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Device Type:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.device_type ?? ''}{' '}
                  </div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Browser:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.browser_version ?? ''}{' '}
                  </div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    OS:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.os_version ?? ''}{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p--sm w--full d--flex align-items--center justify-content--center bg--white border-top--black-100 gap--sm">
          <Button
            onClick={() => handleDownload(documentDetails?.documentFile)}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            size="sm"
            btnClasses="c--pointer btn"
          >
            Download PDF
          </Button>

          {getOS() && ['windows', 'macos'].includes(getOS()) ? null : (
            <Button
              onClick={() => setOpenModal(true)}
              variant="primary"
              color="black"
              isOutline
              borderColor="primary"
              size="sm"
              btnClasses="c--pointer btn"
            >
              View
            </Button>
          )}
        </div>
      </div>

      {openModal ? (
        <AsideModal
          handleClose={() => setOpenModal(false)}
          title={'View'}
          footerComponent={null}
          headerComponent={null}
          width="full"
          size="auto-xs"
        >
          <div className="d--flex gap--sm w--full">
            <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
              {document}
            </div>
          </div>
        </AsideModal>
      ) : null}
    </div>
  );
};

export default HRAAgreementPreviewDocument;
