import FormInput from '../../components/FormInput';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../components/Button';
import { schema, initialValues } from './constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useIcons, { AgentAgencyIcon } from '../../assets/icons/useIcons';
import { AGENT, CARRIER, MEMBER } from '../../helpers/constants';
import PasswordInput from '../../components/PasswordInput';
import nextereLogo from '../../assets/images/nextere-logo.png';
import { useEffect, useState } from 'react';
import SubmitButton from '../../components/Button/SubmitButton';
import AgentLoginImg from '../../assets/images/agentLoginImg.png';
import { useMutation } from '@tanstack/react-query';
import { login } from './actions';
import { useAlert } from '../../hooks/useAlert';
import LoginOtp from './LoginOtp';
import { linkCodeApi } from '../SignUp/actions';
import { useAuth } from '../../hooks/useAuth';
import LoginCongrats from './LoginCongrats.js';
import Spinner from '../../components/Spinner/index.js';
import { getOS } from '../../helpers/functions.js';
import OuterHeader from './Components/OuterHeader.js';

export default function Login() {
  console.log(getOS());
  const { MemeberIcon } = useIcons();
  // const [showOtp, setShowOtp] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [formData, setFormData] = useState(false);
  const [responseData, setResponseData] = useState({});

  const loginBtnArr = [
    {
      role: AGENT,
      title: 'Agent/Agency',
      icon: <AgentAgencyIcon width={25} height={25} />,
    },
    {
      role: MEMBER,
      title: 'Consumer',
      icon: <MemeberIcon width={23} height={23} />,
    },
    // {
    //   role: CARRIER,
    //   title: 'Carrier',
    //   icon: <CarrierIcon width={18} height={18} />,
    // },
  ];

  // const { mutate, isLoading } = useLogin();
  const navigate = useNavigate();
  const [type, setType] = useState(AGENT);
  const { showAlert } = useAlert();
  const { setUser, setUserData } = useAuth();
  const params = useSearchParams();
  const uniqueCode = params?.[0]?.get('uniqueCode');

  const { mutate, isLoading } = useMutation({
    mutationFn: login,
    mutationKey: 'login',
    onSuccess: ({ data }) => {
      setShowOtp(true);

      if (data?.data?.isVerify) {
        showAlert({ type: 'success', message: data?.data?.message });
        setFormData(data?.data);
      } else {
        setResponseData(data?.data);
      }
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const onSubmit = (values) => {
    mutate({
      ...values,
      role: type,
    });
  };

  const { mutate: linkCodeApiMutate, isLoading: isLoadingLinkAPI } =
    useMutation({
      mutationFn: linkCodeApi,
      onSuccess: ({ data }) => {
        setUser({
          token: data?.data?.token?.token,
          role: data?.data?.role,
        });
        setUserData(data?.data);
        navigate(data?.data?.role === MEMBER ? '/agents' : '/dashboard');
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        showAlert({ type: 'danger', message: errMsg });
        navigate('/');
      },
    });

  useEffect(() => {
    if (uniqueCode) {
      localStorage.removeItem('nph-agency-user-role');
      localStorage.removeItem('nph-agency-acc-tkn');
      setUserData(null);

      linkCodeApiMutate({ uniqueCode });
    }
  }, [uniqueCode]);

  if (uniqueCode) return null;

  if (isLoadingLinkAPI)
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );

  return (
    <>
      {showOtp ? (
        <>
          {responseData && Object.keys(responseData).length > 0 ? (
            <LoginCongrats {...{ responseData }} />
          ) : (
            <LoginOtp {...{ formData, setShowOtp, setResponseData }} />
          )}
        </>
      ) : (
        <>
          <OuterHeader />
          <div className="w--full h--full  d--flex loginPage m-t--64 overflow--y-auto flex--column-xs">
            <div className="w--full h--full  p--xl d--flex align-items--center  p--xl flex--column bg--white ">
              <div className="w-max--600 w--full d--flex flex--column gap--lg">
                {/* <div className="w--full gap--sm d--flex flex--column  justify-content--center m-b--sm">
                  <h3 className="font--2xl text--primary ">
                    <img
                      src={nextereLogo}
                      alt="nextereLogo"
                      className="w-max--150"
                    />
                  </h3>
                </div> */}

                <div className="radius--md  gap--xl  w--full  p-t--xl z-index--xs bg--white d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
                  <div className="w--full">
                    <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center m-b--xl">
                      <div className="w--full  d--flex align-items--center font--600 font--md">
                        Login to continue
                      </div>
                    </div>
                    <form
                      className="w--full d--flex flex--column gap--sm"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <div className="d--flex flex--column gap--lg w--full">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              type="input"
                              label="Email Address"
                              placeholder="Enter your email address"
                              error={errors?.email?.message}
                              height="50"
                            />
                          )}
                        />

                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <PasswordInput
                              {...field}
                              label="Password"
                              paddingRight="2xl"
                              placeholder="Enter your password"
                              id="password"
                              error={errors?.password?.message}
                              height="50"
                            />
                          )}
                        />
                      </div>
                      <div className="w--full">
                        <div
                          className="w--full  d--flex justify-content--end c--pointer"
                          onClick={() => {
                            navigate('/forgot-password');
                          }}
                        >
                          Forgot Password?
                        </div>
                      </div>
                      <div className="form-group w--full m-t--sm d--flex align-items--center justify-content--center m-t--lg">
                        <SubmitButton
                          btnClasses="btn font--md"
                          disabled={isLoading}
                          size="lg"
                        >
                          {isLoading ? 'Please wait...' : 'Login'}
                        </SubmitButton>
                      </div>
                    </form>
                  </div>
                </div>

                {type !== CARRIER ? (
                  <div className="w--full d--flex flex--column align-items--center justify-content--start gap--lg font--sm   ">
                    <div className="white-space--nowrap w--full d--flex gap--sm align-items--center justify-content--center m-t--md font--md font--500">
                      Don't have an account? Sign up to continue as
                    </div>
                    <div className="w--full d--flex gap--md">
                      {loginBtnArr.map(({ role, title, icon }, id) => {
                        return (
                          <div className="w--full" key={id}>
                            <Button
                              btnClasses={`
                             
                            btn text--black  gap--sm`}
                              type="submit"
                              size="lg"
                              isOutline
                              data-link-hover
                              // variant="transparent"
                              borderColor={'black-100'}
                              variant={'transparent'}
                              color="black"
                              icon={icon}
                              onClick={() => {
                                navigate('/sign-up', {
                                  state: { userType: role },
                                });
                                // setType(role);
                              }}
                            >
                              {title}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="h-min--17 m-t--md"></div>
                )}
              </div>
            </div>
            <div className="w--full bg--black-50 h--full d--flex  flex--column justify-content--between align-items--center">
              <div className="w--full d--flex justify-content--center p-r--4xl">
                <img src={AgentLoginImg} alt="loginImg" className="p-r--3xl" />
              </div>

              <div className="w--full d--flex flex--column p-l--4xl gap--sm p-b--md">
                <div className="font--xl font--600">Welcome to Conxent!</div>
                <div className="font--sm w--full">
                  Create an account and access all your information with just
                  one click.
                </div>

                <div className="d--flex gap--sm m-t--md ">
                  <div className="h-min--12 w-min--40 w-max--28 radius--md bg--primary"></div>
                  <div className="h-min--12 w-min--12 w-max--12 radius--md bg--black-100"></div>
                  <div className="h-min--12 w-min--12 w-max--12 radius--md bg--black-100"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
