import React, { useEffect, useState } from 'react';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { defaultValues, relationArr } from './constants';
import { hraDetails } from './actions';
import { useAuth } from '../../hooks/useAuth';
import { State, City } from 'country-state-city';
import { useSearchParams } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';
import { getObjectLength } from '../../helpers/functions';
import ActionConfirmation from '../../components/ActionConfirmation';
import HRAForm from './components/HRAForm';
import HRAFormView from './components/HRAFormView';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));
const HRAView = ({ handleClose = () => {}, uuId = '' }) => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const consumerId = searchParams.get('id');

  const { CrossRoundIcon } = useIcons();
  const [selectedField, setSelectedField] = useState('');
  const [cityList, setCityList] = useState([]);
  const [relationList, setRelationList] = useState(relationArr);
  const [carrierList, setCarrierList] = useState([]);
  const [providerList, setProviderList] = useState([]);

  const { data } = useQuery({
    queryKey: ['getHRA', uuId],
    queryFn: () =>
      hraDetails({
        uuId,
        type: user?.role,
      }),
    select: (data) => data?.data?.data,
    enabled: Boolean(uuId),
  });
  const {
    register,

    watch,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,

    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { fields, remove } = useFieldArray({
    control: control,
    name: 'member',
  });

  let [watchState] = watch(['consumer_info.state']);
  useEffect(() => {
    if (data && getObjectLength(data)) {
      const { consumerHraAssessmentInfo, consumerHraAssessmentList = [] } =
        data || {};
      if (consumerHraAssessmentInfo || consumerHraAssessmentList?.length > 0) {
        reset({
          consumer_info: consumerHraAssessmentInfo?.consumerInfo,
          member: consumerHraAssessmentList?.map((val) => {
            const { consumerRelationship, _id } = val || {};
            return { ...consumerRelationship, relationId: _id };
          }),
        });
      }
      setSelectedField(0);
    }
  }, [data, carrierList, providerList]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    } else {
      setCityList([]);
    }
  }, [watchState]);

  useEffect(() => {
    if (fields.length === 1) {
      setSelectedField(0);
    }
  }, [fields]);

  useEffect(() => {
    if (watch('member')) {
      const existingRelations = watch('member').map((item) =>
        item.relation?.trim(),
      );
      const filteredArr = relationArr.filter(
        (item) => !existingRelations.includes(item.value),
      );

      setRelationList(filteredArr);
    }
  }, [JSON.stringify(watch('member') || [])]);

  const fetchIssuers = async () => {
    try {
      const response = await fetch(
        `https://marketplace.api.healthcare.gov/api/v1/issuers?apikey=d687412e7b53146b2631dc01974ad0a4&year=${2024}&state=${watchState}&limit=${25}&offset=${0}`,
      );
      const result = await response.json();
      setCarrierList(result?.issuers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchIssuers();
  }, [watchState]);

  const fetchProviders = async () => {
    try {
      const response = await fetch(
        `https://marketplace.api.healthcare.gov/api/v1/providers/autocomplete?apikey=d687412e7b53146b2631dc01974ad0a4&q=hospital&zipcode=19123&type=Facility`,
      );
      const result = await response.json();
      setProviderList(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <form className="d--flex flex--column gap--xl p--md">
      <div className="d--flex flex--column  border-full--black-100 radius--md gap--xl ">
        <div className="w--full d--flex  gap--lg p--lg h-min--100">
          <div className="w--full d--flex">
            <FormSelect
              label="Year"
              placeholder="Select Year"
              options={[
                {
                  label: '2024',
                  value: '2024',
                },
                {
                  label: '2025',
                  value: '2025',
                },
              ]}
              {...register('consumer_info.year')}
              error={errors?.consumer_info?.year?.message}
              disabled
              extraClasses="c--not-allowed"
            />
          </div>
          <div className="w--full">
            {' '}
            <Controller
              name="consumer_info.state"
              control={control}
              rules={{
                onChange() {
                  setValue('consumer_info.city', '');
                },
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="State"
                  error={errors?.consumer_info?.state?.message}
                  height="40"
                  options={statesList}
                  placeholder="Select State"
                  disabled
                  extraClasses="c--not-allowed"
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="consumer_info.city"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="City "
                  error={errors?.consumer_info?.city?.message}
                  height="40"
                  options={cityList}
                  placeholder="Select City"
                  disabled
                  extraClasses="c--not-allowed"
                />
              )}
            />
          </div>
          <div className="w--full d--flex">
            <FormInput
              label="Address"
              placeholder="#"
              error={errors?.consumer_info?.address?.message}
              {...register(`consumer_info.address`)}
              disabled
              extraClasses="c--not-allowed"
            />
          </div>
        </div>
      </div>

      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100  overflow--auto">
        {fields.map((field, index) => {
          const isErrorExist = Boolean(errors?.member?.[index]?.data);
          return (
            <div className="d--flex align-items--center">
              <div
                className={`p-t--sm p-b--sm p-l--md p-r--md h-min--40 c--pointer font--sm font--600 d--flex align-items--center border-bottom--transparent justify-content--center gap--sm white-space--nowrap radius--topLeftRight--sm  ${
                  selectedField === index
                    ? `border-bottom--black ${
                        isErrorExist ? 'text--danger' : 'text--black'
                      }  bg--black-50`
                    : `  ${
                        isErrorExist ? 'text--danger-600' : 'text--black-600'
                      }`
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedField(index);
                }}
                key={field.id}
              >
                {watch(`member.${index}.relation`)}
                {/* {field.relation} */}
              </div>
            </div>
          );
        })}
      </div>
      {fields.map((field, index) => {
        if (index !== selectedField) return null;
        const err = errors?.member?.[index]?.data;
        return (
          <HRAFormView
            {...{
              register,
              errors: err,
              watch,
              setValue,
              index: selectedField,
              getValues,
              trigger,
              consumerId,
              carrierList,
              providerList,
              rowData: data?.consumerHraAssessmentList[index],
            }}
          />
        );
      })}
    </form>
  );
};

export default HRAView;
